import React, { useEffect, useState } from "react";
import classes from './StartNow.module.scss';
import { useTranslation } from "react-i18next";
import paint_01 from '../../../assets/img/HomePage/StartNow/paint_01.png';
import paint_02 from '../../../assets/img/HomePage/StartNow/paint_02.png';
import Form from "../../GlobalComponents/Form/Form";

const StartNow = () => {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section className={classes.container}>
            {windowWidth > 992 &&
                <>
                    <img className={`${classes.paint} ${classes.paint_01}`} src={paint_01} alt='' />
                    <img className={`${classes.paint} ${classes.paint_02}`} src={paint_02} alt='' />
                </>
            }
            <div className="mt container">
                <div className={classes.wrap}>
                    <h2 className={`${classes.title} font-48`}>
                        {t('home.start_now.title')}
                    </h2>
                    <h4 className={`${classes.subtitle} font-24`}>
                        {t('home.start_now.subtitle')}
                    </h4>
                    <div className={classes.formWrap}>
                        <Form />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default StartNow;
