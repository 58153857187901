import logo from '../assets/img/logo.png';
import image_01 from '../assets/img/HomePage/banner.jpg';
import image_02 from '../assets/img/ServicesPage/banner.jpg';
import image_03 from '../assets/img/FaqPage/banner.jpg';
import image_04 from '../assets/img/banner_arrow.svg';

export const images = [
    logo,
    image_01,
    image_02,
    image_03,
    image_04,
];
