import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import arrow from '../../../assets/img/banner_arrow.svg';
import image from '../../../assets/img/FaqPage/banner.jpg';
import { Fade } from "react-reveal";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h1 className={classes.title}>
                        <div className={`${classes.ttl_01} font-128`}>
                            <Fade left>
                                FAQs
                            </Fade>
                        </div>
                        <Fade left top>
                            <img className={classes.arrow} src={arrow} alt='' />
                        </Fade>
                    </h1>
                    <Fade left>
                        <p className={`${classes.text} font-20`}>
                            {t('faq.banner.text')}
                        </p>
                    </Fade>
                </div>
                <Fade right>
                    <img className={classes.image} src={image} alt="Webcraft" />
                </Fade>
            </div>
        </section>
    );
};

export default Banner;
