import React, { useEffect, useState } from "react";
import classes from './Underbanner.module.scss';
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import image_01 from '../../../assets/img/HomePage/Underbanner/image_01.png';
import image_02 from '../../../assets/img/HomePage/Underbanner/image_02.png';
import image_03 from '../../../assets/img/HomePage/Underbanner/image_03.png';
import image_04 from '../../../assets/img/HomePage/Underbanner/image_04.png';
import { Fade } from "react-reveal";

const cards = [
    {
        image: image_01,
        title: 'home.underbanner.card_01.title',
        text: 'home.underbanner.card_01.text',
    },
    {
        image: image_02,
        title: 'home.underbanner.card_02.title',
        text: 'home.underbanner.card_02.text',
    },
    {
        image: image_03,
        title: 'home.underbanner.card_03.title',
        text: 'home.underbanner.card_03.text',
    },
    {
        image: image_04,
        title: 'home.underbanner.card_04.title',
        text: 'home.underbanner.card_04.text',
    },
];

const Underbanner = () => {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { lang } = useParams();

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section className={`mt container ${classes.container}`}>
            <div className={classes.wrap}>
                {cards.map((card, index) =>
                    <Fade bottom key={index}>
                        <div className={classes.card}>
                            <div className={`${classes.num} font-64`}>
                                0{index + 1}
                            </div>
                            <div className={classes.content}>
                                <h4 className={`${classes.title} font-36`}>
                                    {t(card.title)}
                                </h4>
                                <div className={classes.text}>
                                    {t(card.text)}
                                </div>
                            </div>
                            <Link to={`/${lang}/services`} className={classes.btn}>
                                <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M5 12L19 12" stroke="#75C5FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 19L19 12L12 5" stroke="#75C5FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </Link>
                            {windowWidth > 1220 &&
                                <img className={classes.image} src={card.image} alt='' />
                            }
                        </div>
                    </Fade>
                )}
            </div>
        </section>
    );
};

export default Underbanner;
